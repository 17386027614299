import React from 'react';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';
import messagesFr from 'locales/fr.json';
import messagesEn from 'locales/en.json';
import Header from './Header';
import Footer from './Footer';

export interface IndexProps {
  pageContext: Record<string, string>;
}
const messages: Record<string, unknown> = {
  fr: messagesFr,
  en: messagesEn,
};

const Layout: React.FC<IndexProps> = ({ children, pageContext }) => (
  <>
    <IntlProvider
      locale={pageContext.language}
      messages={flatten(messages[pageContext.language])}
    >
      <Header />
      <main>{children}</main>
      <Footer />
    </IntlProvider>
  </>
);

export default Layout;
