import React from 'react';
import styles from './LegalLink.module.scss';

type FooterLegalLinkProps = {
   link: string;
   isModalLink?: boolean;
   onModalLinkClick?: () => void;
}
const FooterLegalLink: React.FC<FooterLegalLinkProps> = ({
  children, link, isModalLink = false, onModalLinkClick = (): void => { /* empty */ },
}) => {
  const plainLink = (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.footerLink}
    >
      {children}
    </a>
  );
  const modalLink = (
    <button
      type="button"
      onClick={onModalLinkClick}
      className={styles.footerModalLink}
    >
      {children}
    </button>
  );

  const linkView = !isModalLink ? plainLink : modalLink;
  return linkView;
};

export default FooterLegalLink;
