/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Nav,
  Navbar,
  NavDropdown,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Menu from 'helpers/constants/menuConstants';
import Links from 'helpers/constants/links';
import ActionLinkButton from 'components/UI/Buttons/ActionLinkButton';
import GeneralSettings from 'helpers/constants/settings';
import { HeaderMenuProps } from 'components/Header/Menu';

import logo from 'images/logo.svg';
import logoSmall from 'images/logos/logo-mobile.svg';
import closeIcon from 'images/header/close.svg';

import cmdStyles from '../Header.module.scss';
import styles from './MobileMenu.module.scss';
import './MobileMenuOverride.scss';

const HeaderMenuMobile: React.FC<HeaderMenuProps> = ({
  b2cLink,
  featuresLink,
  pricingLink,
  faqLink,
  aboutLink,
}) => {
  const [isLogoNormal, setlLogo] = useState(false);
  const [expandedMenu, setExpandMenu] = useState(false);

  useEffect(() => {
    setlLogo(window.innerWidth > GeneralSettings.MOBILE_MENU.SMALL_LOGO_USE);
  }, []);

  const toggleMenu = (): void => {
    setExpandMenu(!expandedMenu);
  };

  const ProductSubMenuSection = (
    <NavDropdown title="Product" id="collasible-nav-dropdown">
      <NavDropdown.Item href={featuresLink}>
        <FormattedMessage id="components.header.product_submenu.features" />
      </NavDropdown.Item>
      <NavDropdown.Item href={Menu.HEADER.APPS}>
        <FormattedMessage id="components.header.product_submenu.apps" />
      </NavDropdown.Item>
    </NavDropdown>
  );

  const FeaturesLink = (
    <Nav.Link href={featuresLink}>
      <FormattedMessage id="components.header.product_submenu.features" />
    </Nav.Link>
  );

  const ProductSection = GeneralSettings.DESKTOP_MENU.PRODUCT_SUBMENU
    ? ProductSubMenuSection : FeaturesLink;

  const collapseIcon = expandedMenu
    ? (<span className={styles.closeIcon} style={{ backgroundImage: `url(${closeIcon})` }} />)
    : <span className="navbar-toggler-icon" />;

  const shadowBottom = expandedMenu ? styles.shadowedBottom : '';

  return (
    <Navbar
      expanded={expandedMenu}
      collapseOnSelect
      expand="lg"
      variant="light"
      className={shadowBottom}
    >
      <Navbar.Brand href="/">
        <img className={cmdStyles.logo} src={isLogoNormal ? logo : logoSmall} alt="OnoffBusiness" />
      </Navbar.Brand>
      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleMenu}
        data-toggle="collapse"
        data-target="#responsive-navbar-nav"
      >
        {collapseIcon}
      </button>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {ProductSection}
          <Nav.Link href={pricingLink}>
            <FormattedMessage id="components.header.pricing" />
          </Nav.Link>
          <Nav.Link href={aboutLink}>
            <FormattedMessage id="components.header.about" />
          </Nav.Link>
          <Nav.Link href={faqLink}>
            <FormattedMessage id="components.header.faq" />
          </Nav.Link>
          <Nav.Link href={b2cLink} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="components.header.consumer" />
          </Nav.Link>
          <div className={styles.centerLinkItem}>
            <ActionLinkButton
              url={Links.PAGES.SIGNUP}
              customStyle={{ padding: '0 20px 0 20px', minWidth: '100px', height: '46px' }}
            >
              <FormattedMessage id="components.header.tryForFree" />
            </ActionLinkButton>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HeaderMenuMobile;
