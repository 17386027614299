import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

interface IntlProps {
  to: string;
  children: any;
  id?: string;
  className?: string;
  activeClassName?: string;
  onClick?: (e: React.MouseEventHandler<HTMLButtonElement>) => void;
}

const Link: React.FC<IntlProps> = ({
  to,
  children,
  activeClassName,
  className,
  id,
  onClick,
}) => {
  const handleClick = (e: React.MouseEventHandler<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <GatsbyLink
      to={to}
      onClick={() => handleClick}
      activeClassName={activeClassName}
      id={id}
      className={className}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
