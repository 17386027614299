/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Button } from 'react-bootstrap';

import Links from 'helpers/constants/links';
import styles from './ActionLinkButton.module.scss';

type buttonType = 'button' | 'submit' | 'reset';

export enum Themes {
  Main = 'main',
  Green = 'green'
}

type linkOptionsType = {
  target: string;
  rel: string;
}

export interface ActionLinkButtonProps {
  url: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  type?: buttonType;
  theme?: string;
  linkOptions?: linkOptionsType;
  customStyle?: React.CSSProperties;
}

const ActionLinkButton: React.FC<ActionLinkButtonProps> = ({
  children,
  url = Links.PAGES.SIGNUP,
  onClick = (): void => { /* do nothing */ },
  customStyle = undefined,
  type = 'button',
  theme = Themes.Main,
  linkOptions = { target: '_blank', rel: 'noopener noreferrer' },
  disabled = false,
}) => {
  let styleMain = styles.main;
  const custStyle = customStyle !== undefined ? customStyle : {};
  if (theme === Themes.Green) {
    styleMain = styles.themeGreen;
  }
  return (
    <a
      href={url}
      target={linkOptions.target}
      rel={linkOptions.rel}
    >
      <Button
        variant="primary"
        className={styleMain}
        type={type}
        disabled={disabled}
        style={custStyle}
        onClick={(e: React.MouseEvent): void => { onClick(e); }}
      >
        {children}
      </Button>
    </a>
  );
};

export default ActionLinkButton;
