import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Link from 'components/UI/Link';
import GeneralSettings from 'helpers/constants/settings';
import {
  getDownloadAppLink,
  getOnoffB2CLink, stringContainsWords,
  FlattenJson,
} from 'helpers/utils/url';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';
import { getLocale, Locales } from 'helpers/utils/locale';
import { flatten } from 'flat';
import Menu from 'helpers/constants/menuConstants.json';

import b2bhiringEn from 'images/header/b2bhiringEn.png';
import b2bhiringFr from 'images/header/b2bhiringFr.png';
import logoBig from 'images/logo.svg';
import logoMedium from 'images/logos/logo-mobile.svg';
import logoSmall from 'images/logos/logo-small.svg';

import HeaderMenu from './Menu';
import HeaderMenuMobile from './MobileMenu';
import styles from './Header.module.scss';

const ViewPortWidthCollapse = GeneralSettings.MOBILE_VIEW.HEADER_MENU; // px

const Header: React.FC = () => {
  const { locale } = useIntl();
  const { LEGAL } = Menu;
  const flattenMenu: FlattenJson = flatten(Menu);
  // only once else cause flicker
  const Settings = GeneralSettings.DESKTOP_MENU;
  const [screenWidth, setScreenWidth] = useState(1024);
  const [isMobile, setMobile] = useState(false);
  const [isSmallLogo, setSmallLogo] = useState(false);
  const [logo, setLogo] = useState(logoBig);
  const jobsOfferLink = getLocale(locale) === Locales.En
    ? LEGAL.JOBS_OFFER_EN : LEGAL.JOBS_OFFER_FR;
  const downloadLink = getDownloadAppLink(locale);
  const b2cLink = getOnoffB2CLink(locale);
  const featuresLink = flattenMenu[`HEADER.FEATURES.URL.${locale}`];
  const pricingLink = flattenMenu[`HEADER.PRICING.URL.${locale}`];
  const faqLink = flattenMenu[`HEADER.FAQ.URL.${locale}`];
  const aboutLink = flattenMenu[`HEADER.WHOWEARE.URL.${locale}`];
  const [displayHiringLabel, setDisplayHiringLabel] = useState(false);

  const handleLogo = (innerWidth: number): void => {
    if (innerWidth < 1110) {
      setLogo(logoSmall);
      return;
    }

    if (innerWidth < 1300) {
      setLogo(logoMedium);
      return;
    }

    setLogo(logoBig);
  };

  const updateWidth = (): void => {
    const { innerWidth } = window;
    setScreenWidth(innerWidth);
    handleLogo(innerWidth);
    setSmallLogo(innerWidth < Settings.SMALL_LOGO_MIN);
    setMobile(isMobileOS() && !isTablet());
  };

  useEffect(() => {
    updateWidth();
    setDisplayHiringLabel(stringContainsWords(window.location.href, 'home', 'accueil'));
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const desktop = (
    <header className={styles.container}>
      <div className={styles.header}>
        <div>
          {displayHiringLabel ? (
            <a href={jobsOfferLink}>
              <img className={styles.logo} src={logo} alt="onoffBusiness logo" />
              <img className={styles.hiring} src={getLocale(locale) === Locales.En ? b2bhiringEn : b2bhiringFr} alt="hiring link" />
            </a>
          ) : (
            <Link to="/">
              <img className={styles.logo} src={logo} alt="onoffBuniness logo" />
            </Link>
          )}
        </div>

        <div className={styles.desktopMenuItems}>
          <HeaderMenu
            isSmallLogo={isSmallLogo}
            downloadLink={downloadLink}
            b2cLink={b2cLink}
            featuresLink={featuresLink}
            pricingLink={pricingLink}
            faqLink={faqLink}
            aboutLink={aboutLink}
          />
        </div>
      </div>
    </header>
  );
  const mobile = (
    <HeaderMenuMobile
      isSmallLogo={isSmallLogo}
      downloadLink={downloadLink}
      b2cLink={b2cLink}
      featuresLink={featuresLink}
      pricingLink={pricingLink}
      faqLink={faqLink}
      aboutLink={aboutLink}
    />
  );

  const autoMenu = !isMobile && screenWidth > ViewPortWidthCollapse ? desktop : mobile;
  return (
    <>
      {autoMenu}
    </>
  );
};

export default Header;
