/* eslint-disable import/prefer-default-export */
import { flatten } from 'flat';
import Menu from '../constants/menuConstants.json';
import Links from '../constants/links';
import { getLocale, Locales } from './locale';

export interface FlattenJson {
  [key: string]: string;
}
export interface PagesType {
  URL: FlattenJson;
  COMPONENT_PATH: string;
}
export interface MenuHeader {
  [key: string]: PagesType;
}

export function removeHashSignFromLink(hashTag: string): string {
  return hashTag.substr(1, hashTag.length);
}

export function getDownloadAppLink(locale: string): string {
  const { EN, FR } = Links.PAGES.DOWNLOAD_APP;
  const donwloadLink = getLocale(locale) === Locales.En ? EN : FR;
  return donwloadLink;
}

export function getOnoffB2CLink(locale: string): string {
  const { EN, FR } = Links.PAGES.ONOFF_B2C;
  const b2cLink = getLocale(locale) === Locales.En ? EN : FR;
  return b2cLink;
}

export const stringContainsWords = (
  stringToMatch: string, ...matchingWords: string[]
): boolean => matchingWords.some((word) => stringToMatch.includes(word));

export const returnTranslatedUrl = (
  previousLang: string, targetLang: string, currentPage: string,
): string => {
  const MenuHeader: MenuHeader = Menu.HEADER;
  const flattenMenu: FlattenJson = flatten(Menu);
  const match = Object.values(MenuHeader).find(
    (element) => element.URL[previousLang] === currentPage,
  );
  if (match) {
    return match.URL[targetLang];
  }
  return flattenMenu[`HEADER.HOME.URL.${targetLang}`];
};
