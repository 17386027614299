// eslint-disable-next-line import/prefer-default-export
export const detectMobileOs = (): 'ios' | 'android' | 'other' => {
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) return 'ios';
  if (navigator.userAgent.match(/Android/i)) return 'android';

  return 'other';
};

export const isMobileOS = (): boolean => {
  const os = detectMobileOs();
  return os === 'ios' || os === 'android';
};

export const isTablet = (): boolean => {
  const toMatch = [
    /Tablet/i,
    /Nexus 7/i,
    /Nexus 10/i,
    /iPad/i,
    /KFAPWI/i,
    /windows mobile/i,
    /blackberry/i,
  ];
  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};
