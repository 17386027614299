import React from 'react';
import {
  Modal,
  Container,
} from 'react-bootstrap';
import styles from './ContentModal.module.scss';
import './ModalOverride.scss';

type ContentModalProps = {
  title: string;
  contentUrl: string;
  show: boolean;
  hide: () => void;
}

const ContentModal: React.FC<ContentModalProps> = ({
  children, title, contentUrl, show, hide,
}) => {
  const titleClass = `justify-content-center ${styles.title}`;
  return (
    <Modal show={show} onHide={hide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className={styles.modalHeader}>
        <Modal.Title className={titleClass}>
          {children}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Container className={styles.content}>
          <iframe title={title} src={contentUrl} className={styles.frame} frameBorder="0" />
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ContentModal;
