import React from 'react';
import Links from 'helpers/constants/links';

import styles from './SocialLink.module.scss';

import twitter from './icons/twitter.svg';
import facebook from './icons/facebook.svg';
import linkedin from './icons/linkedin.svg';

type ServiceId = 'twitter' | 'facebook' | 'linkedin';

interface Service {
  link: string;
  name: string;
  icon: string;
}

type Services = { [key in ServiceId]: Service };

const SERVICES: Services = {
  twitter: {
    link: Links.SOCIAL.TWITTER,
    name: '',
    icon: twitter,
  },
  facebook: {
    link: Links.SOCIAL.FACEBOOK,
    name: '',
    icon: facebook,
  },
  linkedin: {
    link: Links.SOCIAL.LINKEDIN,
    name: '',
    icon: linkedin,
  },
};

interface SocialLinkProps {
  service: ServiceId;
}

const SocialLink: React.FC<SocialLinkProps> = ({ service }) => (
  <a
    href={SERVICES[service].link}
    className={styles.link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={SERVICES[service].icon} alt="" className={styles.icon} />
    {SERVICES[service].name}
  </a>
);

export default SocialLink;
