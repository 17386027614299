export default {
  SOCIAL: {
    FACEBOOK: 'https://www.facebook.com/onoffapp' as const,
    TWITTER: 'https://twitter.com/onoff' as const,
    LINKEDIN: 'https://www.linkedin.com/showcase/onoff-business/' as const,
  },
  BANNERS: {
    TRY_FREE: 'https://web.onoffbusiness.com/register',
    DEMO: 'https://calendly.com/onoff-business/presentation-onoff-business?month=2020-04',
  },
  PAGES: {
    GROUP_MAIN: 'https://www.onoff.app/home/' as const,
    PRICING_TRY_FREE: 'https://web.onoffbusiness.com/register' as const,
    SIGNUP: 'https://web.onoffbusiness.com/register' as const,
    ONOFF_NUMBERS: 'https://onoff.app' as const, // b2c
    LOGIN: 'https://web.onoffbusiness.com/login' as const,
    HOME_EXPLAIN_VIDEO: 'https://www.youtube.com/embed/DuHaYj_dbAI' as const,
    HOME_EXPLAIN_VIDEO_FR: 'https://www.youtube.com/embed/MDgEUdC1sNs' as const,
    DOWNLOAD_APP: {
      EN: 'https://onoff.app.link/organic/en' as const,
      FR: 'https://onoff.app.link/organic' as const,
    },
    ONOFF_B2C: {
      EN: 'https://www.onoff.app/home/' as const,
      FR: 'https://www.onoff.app/accueil/' as const,
    },
  },
  LEGAL: {
    COOKIES_POLICY: 'https://www.onoff.app/privacy-policy/' as const,
  },
  APP: {
    ANDROID: 'http://onoffapp.com/business/android' as const, // @todo
    IOS: 'http://onoffapp.com/business/ios' as const, // @todo
  },
  DESKTOP: {
    MAC: 'http://onoffapp.com/download/mac' as const,
    WIDNOWS: 'http://onoffapp.com/download/windows' as const,
  },
  TEAMS: {
    FR: 'https://hello.onoffbusiness.com/teams/fr/' as const,
    EN: 'https://hello.onoffbusiness.com/teams/en/' as const,
  },
};
