import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'components/UI/Link';
import { useOutsideClickHandler } from 'helpers/hooks/interaction';

import Menu from 'helpers/constants/menuConstants';
import headStyles from '../Menu.module.scss';
import styles from '../SubMenus.module.scss';

interface ProductMenuProps {
  onCloseMenu: () => void;
}

const ProductSubMenu: React.FC<ProductMenuProps> = ({
  onCloseMenu,
}) => {
  const wrapperRef = useRef(null);

  useOutsideClickHandler(() => { onCloseMenu(); }, wrapperRef);

  return (
    <div
      ref={wrapperRef}
      className={styles.subMenu}
    >
      <ul>
        <li>
          <Link
            to={Menu.HEADER.FEATURES}
            className={headStyles.navigationLink}
            activeClassName={headStyles.navigationLinkActive}
          >
            <FormattedMessage id="components.header.product_submenu.features" />
          </Link>
        </li>
        <li>
          <Link
            to={Menu.HEADER.APPS}
            className={headStyles.navigationLink}
            activeClassName={headStyles.navigationLinkActive}
          >
            <FormattedMessage id="components.header.product_submenu.apps" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ProductSubMenu;
