/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Link from 'components/UI/Link';
import GeneralSettings from 'helpers/constants/settings';

import Links from 'helpers/constants/links';
import { getLocale, Locales } from 'helpers/utils/locale';

import ActionLinkButton from 'components/UI/Buttons/ActionLinkButton';
import arrowNormal from 'images/header/arrow-down.svg';
import arrowActive from 'images/header/arrow-down-active.svg';

import ProductSubMenu from './Product';
import styles from './Menu.module.scss';

enum SubMenus {
  Product = 'product',
}

export interface HeaderMenuProps {
  isSmallLogo: boolean;
  downloadLink: string;
  b2cLink: string;
  featuresLink: string;
  pricingLink: string;
  faqLink: string;
  aboutLink: string;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({
  isSmallLogo = false,
  b2cLink,
  featuresLink,
  pricingLink,
  faqLink,
  aboutLink,
}) => {
  const { locale } = useIntl();
  const [productSubActive, setProductSubActive] = useState(false);
  const onSubMenuClose = (): void => {
    setProductSubActive(false);
  };
  const centerNav = isSmallLogo ? styles.centered : '';
  const specialPad = getLocale(locale) === Locales.Fr ? styles.navigationTinyPad : '';
  const spListItemPad = getLocale(locale) === Locales.Fr ? styles.menuListShorter : '';
  const itemClass = `${styles.navigationLink} ${spListItemPad} ${styles.menuListShorterXS}`;

  const ProductSubMenuSection = productSubActive
    ? (<ProductSubMenu onCloseMenu={onSubMenuClose} />) : null;
  const onHoverItem = (item: string): void => {
    switch (item) {
      case SubMenus.Product:
      default:
        setProductSubActive(!productSubActive);
        break;
    }
  };

  const ProductWithSubMenu = (
    <Link
      to="#"
      id="product-menu"
      className={itemClass}
      activeClassName={styles.navigationLinkActive}
      onClick={(): void => { onHoverItem(SubMenus.Product); }}
    >
      <FormattedMessage id="components.header.product" />
      <span className={styles.arrowSubMenu}>
        <img
          src={!productSubActive ? arrowNormal : arrowActive}
          alt="v"
        />
      </span>
      {ProductSubMenuSection}
    </Link>
  );

  const FeaturesLink = (
    <Link
      to={featuresLink}
      className={itemClass}
      activeClassName={styles.navigationLinkActive}
    >
      <FormattedMessage id="components.header.product_submenu.features" />
    </Link>
  );

  const ProductView = GeneralSettings.DESKTOP_MENU.PRODUCT_SUBMENU
    ? ProductWithSubMenu : FeaturesLink;

  return (
    <nav className={`${styles.navigation} ${centerNav} ${specialPad}`}>
      {ProductView}
      <Link
        to={pricingLink}
        className={itemClass}
        activeClassName={styles.navigationLinkActive}
      >
        <FormattedMessage id="components.header.pricing" />
      </Link>
      <Link
        to={aboutLink}
        className={itemClass}
        activeClassName={styles.navigationLinkActive}
      >
        <FormattedMessage id="components.header.about" />
      </Link>
      <Link
        to={faqLink}
        className={itemClass}
        activeClassName={styles.navigationLinkActive}
      >
        <FormattedMessage id="components.header.faq" />
      </Link>
      <a
        href={b2cLink}
        target="_blank"
        rel="noopener noreferrer"
        className={itemClass}
      >
        <FormattedMessage id="components.header.consumer" />
      </a>
      <span className={styles.separator}> &nbsp; </span>
      <a
        href={Links.PAGES.LOGIN}
        target="_blank"
        rel="noopener noreferrer"
        className={itemClass}
      >
        <FormattedMessage id="components.header.login" />
      </a>
      <ActionLinkButton
        url={Links.PAGES.SIGNUP}
        customStyle={{
          padding: '0 20px 0 20px',
          minWidth: '100px',
          fontWeight: 'normal',
          height: '45px',
        }}
      >
        <FormattedMessage id="components.header.tryForFree" />
      </ActionLinkButton>
    </nav>
  );
};

export default HeaderMenu;
