import React from 'react';

import styles from './Separator.module.scss';

const LangSeparator: React.FC = () => (
  <div className={styles.separator}>
    <div />
  </div>
);

export default LangSeparator;
