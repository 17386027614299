/* eslint-disable import/prefer-default-export */
// Draw functions helper, force redraw

export function forceRenderItem(id = 'flagImage', initWidth = '16px'): void {
  const el = document.getElementById(id);
  if (el !== null) {
    const valueAdd = parseInt(initWidth, 10) + 1;
    el.style.width = `${valueAdd}px`;
    window.setTimeout(() => { el.style.width = initWidth; }, 500);
  }
}
