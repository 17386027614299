import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import listIcon from 'images/footer/address-list.svg';
import styles from './Address.module.scss';

const FooterAddress: React.FC = () => (
  <Container className={styles.container}>
    <Row>
      <Col className={styles.addressItem}>
        <span className={styles.addressIconFr}>
          <img src={listIcon} alt="list-item" />
        </span>
        <ul className={styles.addressList}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <li>1 Cours de l'Île Seguin</li>
          <li>92100 Boulogne-Billancourt, France</li>
        </ul>
      </Col>
    </Row>
    <Row>
      <Col className={styles.addressItem}>
        <span className={styles.addressIconEst}>
          <img src={listIcon} alt="list-item" />
        </span>
        <ul className={styles.addressList}>
          <li>Viru Väljak 2, Tallinn</li>
          <li>10111 Estonia</li>
        </ul>
      </Col>
    </Row>
  </Container>
);

export default FooterAddress;
