import React, { useEffect, useState } from 'react';
import { forceRenderItem } from 'helpers/utils/draw';
import { returnTranslatedUrl, FlattenJson } from 'helpers/utils/url';
import { useIntl } from 'react-intl';
import { flatten } from 'flat';

import messagesFr from 'locales/fr.json';
import messagesEn from 'locales/en.json';
import iconFr from '../icons/fr.svg';
import iconEn from '../icons/en.svg';

import cmnStyles from '../LanguageSelector.module.scss';
import styles from './Menu.module.scss';

enum Languages {
  EN = 'en',
  FR = 'fr'
}

interface LangMenuProps {
  optionLanguages: Array<string>;
}

const messages: Record<string, FlattenJson> = {
  fr: flatten(messagesFr),
  en: flatten(messagesEn),
};

const LanguageMenu: React.FC<LangMenuProps> = ({
  optionLanguages,
}) => {
  const imgId = 'subFlagImage';
  const [currentPage, setCurrentPage] = useState('');
  const { locale } = useIntl();

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    forceRenderItem(imgId);
  }, []);

  const changeUrl = (language: string): void => {
    window.location.pathname = returnTranslatedUrl(locale, language, currentPage);
  };

  const listLanguages = optionLanguages.map((language) => language !== locale && (
    <li key={language}>
      <div
        className={cmnStyles.selector}
        onClick={(): void => changeUrl(language)}
        onKeyPress={
          (): void => changeUrl(language)
        }
        tabIndex={0}
        role="button"
        aria-label={messages[language]['components.footer.languageSelector.changeLanguage']}
      >
        <img
          id={imgId}
          className={cmnStyles.language}
          src={language === Languages.EN ? iconEn : iconFr}
          alt={language}
        />
        <span className={cmnStyles.title}>{language.toUpperCase()}</span>
      </div>
    </li>
  ));
  return (
    <div className={styles.container}>
      <ul>
        {listLanguages}
      </ul>
    </div>
  );
};

export default LanguageMenu;
