export default {
  MOBILE_VIEW: {
    HEADER_MENU: 900 as const, // px
    SLIDER_MAIN: 992 as const,
    SLIDER_START_NOW: 776 as const,
    NOT_FOUND: 776 as const,
    EXPLAIN_VIDEO: 992 as const,
    PRICING_PDF: 992 as const,
    PRICING_INTRO: 992 as const,
    FOOTER: 992 as const,
  },
  LIVE_CHAT: {
    ENABLED: true as const,
  },
  FAQ: {
    ANCHORMENU_DESKTOP: 1024 as const, // px
  },
  MOBILE_MENU: {
    SMALL_LOGO_USE: 470 as const, // px
  },
  DESKTOP_MENU: {
    SMALL_LOGO_MIN: 1300 as const,
    PRODUCT_SUBMENU: false as const, // @todo remove when App page will be active
  },
  COMPANIES_LIST: {
    SLIDER_INCREASE_VIEW: 992 as const,
  },
  ABOUT: {
    ENABLE_VISIT_PAGE: false as const, // @todo remove this when link will be acitve
  },
};
