/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import { forceRenderItem } from 'helpers/utils/draw';
import { useIntl } from 'react-intl';
import { flatten } from 'flat';
import { FlattenJson } from 'helpers/utils/url';

import messagesFr from 'locales/fr.json';
import messagesEn from 'locales/en.json';
import LanguageMenu from './Menu';

import iconFr from './icons/fr.svg';
import iconEn from './icons/en.svg';

import styles from './LanguageSelector.module.scss';

const messages: Record<string, unknown> = {
  fr: messagesFr,
  en: messagesEn,
};

const LanguageSelector: React.FC = () => {
  const imgId = 'flagImage';
  const { locale } = useIntl();
  const translatedMessages: FlattenJson = flatten(messages[locale]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [chevronCl, setChevronCl] = useState(styles.chevronDw);
  const currentLanguage = locale.toString();

  useEffect(() => {
    forceRenderItem(imgId);
  }, []);

  const showLanguageOptions = (): void => {
    setMenuVisible(!menuVisible);
    setChevronCl(menuVisible ? styles.chevronDw : styles.chevronUp);
  };

  enum Languages {
    EN = 'en',
    FR = 'fr'
  }
  const Drop = menuVisible ? (
    <LanguageMenu
      optionLanguages={Object.values(Languages)}
    />
  ) : null;

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.selector}
        onClick={(): void => showLanguageOptions()}
        onKeyPress={(): void => showLanguageOptions()}
        tabIndex={0}
        aria-haspopup="listbox"
        aria-label={translatedMessages['components.footer.languageSelector.selectLanguage']}
        aria-expanded={menuVisible}
      >
        <img
          id={imgId}
          className={styles.language}
          src={currentLanguage === 'en' ? iconEn : iconFr}
          alt={currentLanguage}
        />
        <span className={styles.title}>{currentLanguage.toUpperCase()}</span>
        <span className={chevronCl}> </span>
        {Drop}
      </button>
    </div>
  );
};

export default LanguageSelector;
