import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
import Link from 'components/UI/Link';

import logo from 'images/logo.svg';
import workEstoniaEASLogo from 'images/footer/work-estonia-eas.svg';

import Menu from 'helpers/constants/menuConstants.json';
import GeneralSettings from 'helpers/constants/settings';
import { isMobileOS } from 'helpers/utils/mobile';
import { getLocale, Locales } from 'helpers/utils/locale';
import { FlattenJson } from 'helpers/utils/url';
import { flatten } from 'flat';

import ContentModal from 'components/UI/ContentModal';
import LangSeparator from 'components/UI/LangSeparator';
import LanguageSelector from 'components/UI/LanguageSelector';
import FooterAddress from './Address';

import FooterLegalLink from './LegalLink';
import SocialLink from './SocialLink';
import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  const { locale } = useIntl();
  const menuFlatten: FlattenJson = flatten(Menu);
  const { LEGAL } = Menu;
  const [isMobileView, setMobileView] = useState(false);
  const [isBlogModalVis, setShowBlogModal] = useState(false);

  useEffect(() => {
    const isMobile = window.innerWidth < GeneralSettings.MOBILE_VIEW.FOOTER || isMobileOS();
    setMobileView(isMobile);
  }, []);

  const blogLink = Menu.LEGAL.BLOG;
  const partnersLink = getLocale(locale) === Locales.En
    ? LEGAL.PARTNERS_EN : LEGAL.PARTNERS_FR;
  const jobsOfferLink = getLocale(locale) === Locales.En
    ? LEGAL.JOBS_OFFER_EN : LEGAL.JOBS_OFFER_FR;
  const confidentialityLink = menuFlatten[`HEADER.CONFIDENTIALITY.URL.${locale}`];
  const termsLink = menuFlatten[`HEADER.CONDITIONS.URL.${locale}`];

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <hr className={styles.breakLine} />
        <Container fluid>
          <Row>
            <Col
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 3, order: 1 }}
              xl={{ span: 3, order: 1 }}
              className={styles.column}
            >
              <Link to="/">
                <img className={styles.logo} src={logo} alt="OnoffBusiness" />
              </Link>
              <Row>
                <Col className={styles.arcep}>
                  <FormattedMessage id="components.footer.arcep" />
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 12, order: 2 }}
              sm={{ span: 12, order: 2 }}
              md={{ span: 12, order: 2 }}
              lg={{ span: 3, order: 2 }}
              xl={{ span: 3, order: 2 }}
              className={styles.column}
            >
              <ul className={styles.legalList}>
                <li className={styles.jobOffers}>
                  <FooterLegalLink
                    link={jobsOfferLink}
                  >
                    <FormattedMessage id="components.footer.jobsOffer" />
                  </FooterLegalLink>
                </li>
                <li>
                  <Link className={styles.link} to={confidentialityLink}>
                    <FormattedMessage id="components.footer.confidentiality" />
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} to={termsLink}>
                    <FormattedMessage id="components.footer.conditions" />
                  </Link>
                </li>
                <li>
                  <FooterLegalLink
                    link={blogLink}
                    isModalLink={!isMobileView}
                    onModalLinkClick={(): void => { setShowBlogModal(true); }}
                  >
                    <FormattedMessage id="components.footer.blog" />
                  </FooterLegalLink>
                </li>
                <li>
                  <FooterLegalLink
                    link={partnersLink}
                  >
                    <FormattedMessage id="components.footer.partners" />
                  </FooterLegalLink>
                </li>
              </ul>
            </Col>
            <Col
              xs={{ span: 12, order: 3 }}
              sm={{ span: 12, order: 3 }}
              md={{ span: 12, order: 3 }}
              lg={{ span: 3, order: 3 }}
              xl={{ span: 3, order: 3 }}
            >
              <FooterAddress />
            </Col>
            <Col
              xs={{ span: 12, order: 4 }}
              sm={{ span: 12, order: 4 }}
              md={{ span: 12, order: 4 }}
              lg={{ span: 3, order: 4 }}
              xl={{ span: 3, order: 4 }}
              className={styles.column}
            >
              <ul className={styles.socialList}>
                <li>
                  <SocialLink service="facebook" />
                </li>
                <li>
                  <SocialLink service="twitter" />
                </li>
                <li>
                  <SocialLink service="linkedin" />
                </li>
                <li>
                  <LangSeparator />
                </li>
                <li>
                  <LanguageSelector />
                </li>
              </ul>
              {locale === Locales.En && (
                <div className={styles.workEstonia}>
                  <img className={styles.workEstoniaLogo} src={workEstoniaEASLogo} alt="Work Estonia" />
                  <div className={styles.workEstoniaNote}>
                    <FormattedMessage id="components.footer.workEstoniaNote" />
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col className={styles.copyright}>
              <span>©</span>
              <span>
                <FormattedDate
                  value={new Date()}
                  year="numeric"
                />
              </span>
              <span> Onoff Telecom</span>
            </Col>
          </Row>
        </Container>
      </div>
      <ContentModal
        show={isBlogModalVis}
        hide={(): void => { setShowBlogModal(false); }}
        title="Blog"
        contentUrl={blogLink}
      >
        <FormattedMessage id="components.footer.blog" />
      </ContentModal>
    </footer>
  );
};

export default Footer;
