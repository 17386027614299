export enum Languages {
  En = 'en',
  Fr = 'fr'
}

export enum Locales {
  En = 'en',
  Intl = 'en',
  Fr = 'fr'
}

enum FAQLocales {
  En = 'en-us',
  Fr = 'fr'
}

export function getLocale(locale: string): string {
  switch (locale) {
    case Locales.En:
    case Locales.Fr:
      return locale;
    case '':
    default:
      return Locales.Intl;
  }
}

export function getFAQLocaleFromLanguage(lang: string): string {
  let locale = FAQLocales.En;
  switch (lang) {
    case Languages.En:
    default:
      locale = FAQLocales.En;
      break;
    case Languages.Fr:
      locale = FAQLocales.Fr;
      break;
  }
  return locale;
}
